import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Div, Span } from '../styles';

const Video = ({ src, width }) => {
  const [isPlaying, setPlaying] = useState(false);
  const videoRef = useRef(null);

  return (
    <Div textAlign="center" width={width} border="0">
      <video
        preload="true"
        autoPlay
        muted
        playsInline
        onPlay={() => setPlaying(true)}
        onPlaying={() => setPlaying(true)}
        onEnded={() => setPlaying(false)}
        ref={videoRef}
      >
        <source src={src} type="video/mp4" />
      </video>

      <motion.button
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0.5,
          margin: '2rem auto',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: isPlaying ? 0 : 0.5 }}
        onClick={() => {
          videoRef.current.play();
        }}
      >
        <svg
          width="20"
          height="23"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: '10px' }}
        >
          <path
            d="M8.972 19.175v3.313c0 .264.288.408.505.24l5.326-4.201a.32.32 0 000-.48l-5.326-4.202c-.192-.167-.505-.024-.505.24v3.266c-4.126-.336-7.365-3.889-7.125-8.138.192-3.913 3.407-7.13 7.318-7.346 4.486-.24 8.204 3.336 8.204 7.754 0 1.368-.36 2.665-.984 3.769a.511.511 0 00.096.624l.624.576c.24.216.6.168.768-.12a9.632 9.632 0 001.295-5.666c-.383-4.513-3.934-8.21-8.42-8.738C4.943-.606 0 3.931 0 9.596c0 5.09 3.983 9.268 8.972 9.579z"
            fill="#000"
            fillRule="evenodd"
            opacity=".5"
          />
        </svg>
        <Span fontSize={['1.5rem', '1.5rem', '1.8rem']}>Replay animation</Span>
      </motion.button>
    </Div>
  );
};

export default Video;
